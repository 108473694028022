@import "./_variables.scss";

.item {
  border-top: 1px solid rgba($color: $dark-gray, $alpha: 0.15);
  display: flex;
  padding: 1rem;

  .img {
    width: 100px;
    height: 100px;
    border-radius: $border-radius;
    border: 1px solid rgba($color: $dark-gray, $alpha: 0.1);
    padding: 5px;

    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .detail {
    flex: 1;
    padding-left: 10px;

    .priceContainer {
      display: flex;
      margin-top: 10px;

      > small {
        font-weight: 600;
        padding: 5px 0;

        &:first-of-type {
          &::after {
            content: "X";
            margin-left: 5px;
            margin-right: 5px;
          }
        }

        &:last-of-type {
          margin-left: auto;
          font-weight: 600;

          &::before {
            content: "=";
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.removeItem {
  > button {
    background-color: $red;
    color: $white;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    cursor: pointer;
  }
}
