@import "./_variables.scss";

.category {
  display: flex;
  flex-direction: column;
  align-items: center;

  .container {
    width: 100%;
    max-width: 1920px;
    margin-top: 1rem;
    padding: 0.5rem;

    .row {
      display: flex;
      flex-wrap: wrap;

      .title {
        padding: 0.5rem;
      }
    }
  }
}
