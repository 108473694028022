@import "./_variables.scss";

.mobileCategories {
  padding: 1rem;

  .mobileCategoriesMenu {
    display: flex;
    flex-direction: column;

    > li {
      width: 100%;
    }
  }
}
