@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600&display=swap");

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border: none;
}

html {
  font-family: "Quicksand", sans-serif;
  font-size: 17px;
  min-height: 100%;
}

body {
  width: 100%;
  height: calc(100%);
  font-family: inherit;
  font-size: inherit;
  line-height: 1.15;
}

ul {
  list-style-type: none;
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}
